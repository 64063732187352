import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { TestId } from './constants/testIds';

import App from './App';
import CreateCompany from './components/company/create_company';
import EditCompany from './components/company/edit_company';
import CreateEmployee from './components/employee/create_employee';
import UpdateEmployee from './components/employee/edit_employee';
import ErrorPage from './components/error_page/error_page';
import UserManagementDashboard from './components/user_management_dashboard/user_management_dashboard';

const LOGOUT = process.env.REACT_APP_LOGOUT_URL || '/';

export const basePaths = {
  createEmployee: '/company/createEmployee',
  editEmployee: '/company/editEmployee',
};

export const routesDefinitions = {
  dashboard: '/',
  createLease: '/createLease',
  createConcept: '/createConcept',
  editDashboard: '/company',
  editCompany: '/company/:companyId',
  createEmployee: '/company/:companyId/createEmployee',
  editEmployee: '/company/:companyId/editEmployee/:employeeId',
  logout: LOGOUT,
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path={routesDefinitions.dashboard}
      element={<App />}
      errorElement={<ErrorPage />}
    >
      <Route errorElement={<ErrorPage />}>
        <Route index element={<UserManagementDashboard />} />
        <Route
          path={routesDefinitions.createLease}
          element={
            <CreateCompany dataTestId={TestId.CREATE_LEASE_COMPANY_FORM} />
          }
        />
        <Route path={routesDefinitions.editCompany} element={<EditCompany />} />
        <Route
          path={routesDefinitions.createEmployee}
          element={<CreateEmployee />}
        />
        <Route
          path={routesDefinitions.editEmployee}
          element={<UpdateEmployee />}
        />
      </Route>
    </Route>
  )
);
